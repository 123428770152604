import { type RequestMeta } from "@koala/sdk";
import { getPopularProducts } from "@koala/sdk/v4";
import { QueryClient, Hydrate } from "@tanstack/react-query";
import {
  type InferGetServerSidePropsType,
  type GetServerSideProps,
} from "next";
import { initUrqlClient, type SSRData, withUrqlClient } from "next-urql";
import { ssrExchange, useQuery } from "urql";
import { Page as ContentPage } from "@/components/content/page";
import { getConfigOverride } from "@/features/configs/configs";
import { getOrganization } from "@/features/configs/organization";
import LocationsFinder from "@/features/locations/finder";
import { createHttpClient } from "@/services/client";
import { contentSetupAPIRequests } from "@/services/content.service";
import { getFooterTemplate } from "@/services/footer.service";
import { type ServerQuery, queryOnServer } from "@/services/ssr.service";
import { HomePageQueryDocument } from "@/types/graphql-schema";
import { getOrigin, getRequestOrigin } from "@/utils";

/*
 * Some brands use commerce+ and some do not, so our entry route
 * shows either the locations finder or the brand's configured homepage
 */
function Index({
  serverState,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const [{ data }] = useQuery({
    query: HomePageQueryDocument,
  });

  if (!Boolean(data?.pages?.length)) {
    return (
      <Hydrate state={serverState}>
        {/* @ts-expect-error */}
        <LocationsFinder />
      </Hydrate>
    );
  }

  return (
    <Hydrate state={serverState}>
      <ContentPage
        id={data?.pages?.[0].id ?? ""}
        seoTitle={data?.pages?.[0].seoTitle ?? ""}
        seoDescription={data?.pages?.[0].seoDescription ?? ""}
        seoImage={data?.pages?.[0].seoImage ?? ""}
        content={data?.pages?.[0].content}
      />
    </Hydrate>
  );
}

interface PageProps {
  urqlState?: SSRData;
  serverState?: unknown;
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  req,
}) => {
  if (!req.headers.host) {
    throw new Error("Missing request origin");
  }

  const queryClient = new QueryClient();
  const meta = getRequestOrigin(req.headers.host);
  const ssrCache = ssrExchange({ isClient: false });
  const session = contentSetupAPIRequests(ssrCache, meta);
  const client = initUrqlClient(session, false);
  const origin = getOrigin(req.headers.host);
  const organizationConfig = await getOrganization(queryClient, origin);
  const isSingleLocation =
    organizationConfig?.locations.features.isSingleLocation;

  if (client) {
    const { data } = await client.query(HomePageQueryDocument, {}).toPromise();
    const isSingleLocationEnabled = Boolean(
      isSingleLocation.enabled && isSingleLocation.storeLocationId
    );
    const hasCommercePlusPages = Boolean(data?.pages?.length);

    /**
     * Redirect to store page if it is single store location organization/brand and no Commerce+ pages are configured
     */
    if (!hasCommercePlusPages && isSingleLocationEnabled) {
      return {
        redirect: {
          destination: `/store/${isSingleLocation.storeLocationId}`,
          permanent: false,
        },
      };
    }
  }

  const { flagship_store_location } = await getConfigOverride(
    "menu",
    queryClient,
    origin
  );

  let serverQueries: ServerQuery[] = [
    {
      queryKey: ["site-footer"],
      queryFn: () => getFooterTemplate(origin),
    },
  ];

  if (flagship_store_location) {
    serverQueries = serverQueries.concat([
      {
        queryKey: ["popular-items", { id: flagship_store_location }],
        queryFn: () =>
          getPopularProducts(
            { locationId: flagship_store_location, maxItems: 15 },
            { client: createHttpClient({ origin }) }
          ),
      },
    ]);
  }

  const serverState = await queryOnServer(serverQueries, queryClient);

  return {
    props: {
      urqlState: ssrCache.extractData(),
      serverState,
    },
  };
};

export default withUrqlClient(
  (ssrExchange, ctx) => {
    let meta: RequestMeta | undefined = undefined;
    if (typeof window !== "undefined") {
      meta = getRequestOrigin(window.location.host);
    } else if (ctx?.req?.headers.host) {
      meta = getRequestOrigin(ctx.req.headers.host);
    }
    return contentSetupAPIRequests(ssrExchange, meta);
  },
  { neverSuspend: true }
)(Index);
